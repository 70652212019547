import React from 'react';

const MadeByLink = () => (
    <a
        href="https://qodile.com"
        target="_blank"
        rel="noopener noreferrer"
    >
        qodile
    </a>
);

export default MadeByLink;
