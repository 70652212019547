import React from 'react';
import TimeItIs from './TimeItIs';
import SocialShare from './SocialShare';

const Main = () => (
    <div className="App">
        <header className="App-header">
            <h1 className="title text-gradient"><i className="fa fa-clock-o" /> TimeIsIt<sup>[BETA]</sup></h1>
            <SocialShare />
        </header>
        <div id="time-section">
            <p><TimeItIs /></p>
        </div>
    </div>
);

export default Main;
