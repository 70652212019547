import React from 'react';
import MadeByLink from './MadeByLink';

const Footer = () => (
    <footer>
        <p>
            ©TimeItIs &mdash; Réalisé par <MadeByLink />
            {' '}avec <i className="fa fa-heart text-pink" />
            {' '}et <i className="fa fa-coffee text-coffee" />
        </p>
    </footer>
);

export default Footer;
