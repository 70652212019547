import React from 'react';
import { FACEBOOK_SHARE_URL, TWITTER_SHARE_URL } from '../constants/config';

const SocialShare = () => (
    <ul className="social-share">
        <li>
            <a
                href={TWITTER_SHARE_URL}
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className="fas fa fa-twitter" />
            </a>
        </li>
        <li>
            <a
                href={FACEBOOK_SHARE_URL} target="_blank"

                rel="noopener noreferrer"
            >
                <i className="fas fa fa-facebook" />
            </a></li>
    </ul>
);

export default SocialShare;
